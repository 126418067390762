<template>
  <b-button v-on:click="deleteCustomer()"
            v-c-tooltip.hover="'Löschen'"
            class="btn-icon"
            variant="outline-danger">
    <i class="fa fa-trash"></i>
  </b-button>
</template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "DeleteCustomer",
  props: {
    customer_id: {
      default: 0
    },
  },
  methods: {
    deleteCustomer () {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du den Schüler wirklich löschen?', {
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/customers/' + self.customer_id).then(function (response) {
            self.$emit('deleted');
          });
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
