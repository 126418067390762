import Http from '@/shared/http-config'
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';

export default {
  namespaced: true,

  state () {
    return {
      states: [],
      channels: [],
      events: [],
      education: {
        id: 0,
        tests: {},
        state: {},
        package: {},
        class: {},
        picture_visibility_model: [],
      },
      packages: {},
      educations: [],
      all_educations: [],
      package_variations: {
        1: 'Standard',
        2: 'Power',
        3: 'VIP'
      },
      search: {
        klassen: [],
        category: null,
        schuelerGebM: null,
        schuelerGebY: null,
        massnahmeID: null,
        last_activity: null,
        teamID: null,
        seminarID: null,
        finishTalk: null,
        filialID: null,
        finishTheory: null,
        eKomiFeedback: null,
        feedback: null,
        jmplEventID: null
      },
    }
  },

  actions: {
    async fetchStates(context, force = false){
      if(context.state.states.length === 0 || force) {
        return await Http.request('get', '/customers/states').then((states) => {
          context.commit('set', ['states', states.data]);
        })
      }
      return await new Promise((resolve) => {
        resolve(context.state.states);
      });
    },
    async fetchEducations(context, customer_id){
      return await Http.request('GET', '/customers/' + customer_id + '/educations').then((response) => {
        let packages = {};
        let educations = [];
        let all_educations = [];
        response.data.forEach(education => {
          if(education.package !== null){
            if (typeof(packages[education.package.id]) === 'undefined') {
              packages[education.package.id] = [];
            }
            packages[education.package.id].push(education);
          }
          else {
            educations.push(education);
          }
          all_educations.push(education);
        });
        context.commit('set', ['packages', packages]);
        context.commit('set', ['educations', educations]);
        context.commit('set', ['all_educations', all_educations]);
      });
    },
    async fetchChannels(context){
      return await Http.request('GET', '/customers/marketing/channels').then((response) => {
        let channels = response.data.map(item => { return { value: item, text: item.title }});
        context.commit('set', ['channels', channels]);
      });
    },
    async fetchEvents(context){
      return await Http.request('GET', '/customers/marketing/events').then((response) => {
        let events = response.data.map(item => { return {
          value: item,
          text: (item.measure ? item.measure.name : '') + ' (' +
            moment(item.start).format('DD.MM.YYYY') +
            (item.start !== item.end ? ' - ' + moment(item.end).format('DD.MM.YYYY') : '') +
          ')'
        }});
        context.commit('set', ['events', events]);
      });
    },
    async saveEducationState(context, { customer_id, education_id, new_status_id }){
      return Http.request('POST', '/customers/' + customer_id + '/educations/' + education_id + '/state/' + new_status_id).then(function (response) {
        context.commit('set', ['education', response.data]);
      });
    },
    resetSearch(context){
      let search = {
        klassen: [],
        category: null,
        schuelerGebM: null,
        schuelerGebY: null,
        massnahmeID: null,
        last_activity: null,
        teamID: null,
        seminarID: null,
        finishTalk: null,
        filialID: null,
        finishTheory: null,
        eKomiFeedback: null,
        feedback: null,
        jmplEventID: null
      };
      context.commit('set', ['search', search]);
    },
  },

  mutations: {
    updateField,
    set (state, [variable, value]) {
      state[variable] = value
    },
  },

  getters: {
    getField,
    get: (state) => (variable) => {
      return state[variable];
    },
  }
};
