import { render, staticRenderFns } from "./StatusBox.vue?vue&type=template&id=48151d67&scoped=true&"
import script from "./StatusBox.vue?vue&type=script&lang=js&"
export * from "./StatusBox.vue?vue&type=script&lang=js&"
import style0 from "./StatusBox.vue?vue&type=style&index=0&id=48151d67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48151d67",
  null
  
)

export default component.exports