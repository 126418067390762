<template>
  <div class="status-box"
       @click="clickBox"
       :class="{'selected': selected, 'cursor-pointer': clickable }"
       v-c-tooltip="{
         content: state.description,
         html: true
       }">
    <div class="status-arrow"></div>
    <div class="status-arrow"></div>
    {{ state.name }}
  </div>
</template>

<script>
  export default {
    name: "StatusBox",
    props: {
      state: {
        type: Object,
        default: function (){
          return {name: '', description: ''}
        }
      },
      selected: {
        type: Boolean,
        default: false
      },
    },
    created() {
      if(typeof(this.$listeners.click) !== 'undefined'){
        this.clickable = true;
      }
    },
    data(){
      return {
        clickable: false
      }
    },
    methods: {
      clickBox(){
        this.$emit('click');
      }
    }
  }
</script>

<style scoped>
.status-box {
  position: relative;
  margin-left: -2px;
  background-color: #ffffff;
  color: var(--primary);
  padding: 0.750rem 1.5rem;
  border: 1px solid var(--primary);
  border-radius: 4px 0 0 4px;
  font-weight: bold;
  text-align: center;
}
.status-box.selected {
  background-color: var(--primary);
  color: #ffffff;
}
.status-arrow {
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 23px solid transparent;
  position: absolute;
  top: -1px;
}
.status-arrow:nth-child(1) {
  border-left: 12px solid #ffffff;
  right: -11px;
  z-index: 20;
}
.status-arrow:nth-child(2) {
  border-left: 12px solid var(--primary);
  right: -12px;
  z-index: 10;
}
.status-box.selected .status-arrow:nth-child(1) {
  border-left: 12px solid var(--primary);
}
.status-box.selected .status-arrow:nth-child(2) {
  border-left: 12px solid #ffffff;
}
</style>
