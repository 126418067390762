<template src="./customers.html"></template>

<script>
import Http from '@/shared/http-config'
import draggable from 'vuedraggable'
import StatusBox from './components/StatusBox'
import BarcodeReturn from './components/BarcodeReturn'
import CustomerSearch from './components/CustomerSearch'
import DeleteCustomer from './components/DeleteCustomer'
import moment from 'moment'
import { isMobile } from '@/shared/utils.js'
import store from '../../Store';
import CustomerStore from './CustomerStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect'

export default {
  name: "Customers",
  components: { draggable, StatusBox, CustomerSearch, BarcodeReturn, DeleteCustomer, Multiselect },
  mixins: [RegisterStoreModule],
  data: function () {
    return {
      classes: [],
      branches: [],
      teachers: [],
      states: [],
      all_states: [],
      search_result: [],
      fields: [
        { key: 'select', label: '' },
        { key: 'customer.first_name', label: 'Vorname' },
        { key: 'customer.last_name', label: 'Nachname' },
        { key: 'class.name', label: 'Klasse' },
        { key: 'customer.phone', label: 'Telefon' },
      ],
      per_page_options: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      customer_types: {
        0: 'Anbahnung',
        1: 'Ausbildung',
        2: 'Abgeschlossen',
      },
      selected_customers: {},
      selected_state: 0,
      sort: {
        col: 'activity',
        order: 'desc'
      },
      select_all_customers: false
    }
  },
  computed: {
    listView () {
      return this.$store.getters.get('listView');
    },
    schuelerGebM (){
      return moment.months().map((month, key) => {return {value: (key + 1), text: month};});
    },
    schuelerGebY (){
      return Array.from(Array(new Date().getFullYear() - 1949), (_, i) => (i + 1950).toString()).reverse();
    },
    isMobile () {
      return isMobile();
    },
    isFiltered () {
      for(let key in this.search) {
        if(key === 'klassen'){
          if(this.search[key].length > 0){
            return true;
          }
        }
        else if(this.search[key] != null){
          return true;
        }
      }
      return false;
    },
    ...mapFields('customers',
      {
        channels: 'channels',
        events: 'events',
        search: 'search',
        searchKlassen: 'search.klassen',
        searchCategory: 'search.category',
        searchSchuelerGebM: 'search.schuelerGebM',
        searchSchuelerGebY: 'search.schuelerGebY',
        searchMassnahmeID: 'search.massnahmeID',
        searchJmplEventID: 'search.jmplEventID',
        searchLastActivity: 'search.last_activity',
        searchTeamID: 'search.teamID',
        searchSeminarID: 'search.seminarID',
        searchFinishTalk: 'search.finishTalk',
        searchFilialID: 'search.filialID',
        searchFinishTheory: 'search.finishTheory',
        searchEKomiFeedback: 'search.eKomiFeedback',
        searchFeedback: 'search.feedback',
      }
    ),
  },
  mounted: function(){
    this.$store.commit('set', ['containerFluid', true]);
  },
  destroyed: function(){
    this.$store.commit('set', ['containerFluid', false]);
  },
  watch: {
    $route(to, from) {
      this.states = {};
      this.getStates();
    },
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
      store.dispatch('fetchClasses'),
      store.dispatch('fetchTeachers')
    ]).finally(() => {
      next((vm) => {})
    })
  },
  created: function(){
    let self = this;
    self.registerStoreModule('customers', CustomerStore);
    self.$store.dispatch('customers/fetchChannels');
    self.$store.dispatch('customers/fetchEvents');
    self.$store.dispatch('customers/fetchStates').then(function (){
      self.branches = self.$store.getters.get('branches');
      self.classes = self.$store.getters.get('classes');
      self.teachers = self.$store.getters.get('teachers');
      self.getStates();
    });
  },
  methods: {
    calcBoardCols (){
      let cols = Math.floor(12 / this.states.length);
      if(this.isMobile){
        cols = cols * 2;
      }
      return cols;
    },
    getStates(){
      let self = this;
      let states = {0:[],1:[],2:[]};
      let all_states = JSON.parse(JSON.stringify(self.$store.getters['customers/get']('states')));
      all_states.forEach(state => {
        states[state.is_customer].push(state);
      });
      self.all_states = JSON.parse(JSON.stringify(states));
      self.states = states[self.$route.params.type];
      if(parseInt(self.$route.params.type) < 2){
        // append first state of next type, for conversion
        self.states.push(states[parseInt(self.$route.params.type) + 1].shift());
      }

      self.states.forEach(state => {
        self.$set(state, 'meta', {
          page: 1,
          per_page: 10
        });
        self.getCustomers(1, self.isMobile ? 10 : 20, state.id).then(function (response){
          self.$set(state, 'customers', response.data);
        });
      });
    },
    loadCustomers(ctx, callback) {
      let self = this;
      self.getCustomers(ctx.currentPage, ctx.perPage, ctx.apiUrl).then(function (response){
        let stateKey = self.states.findIndex(state => parseInt(state.id) === parseInt(ctx.apiUrl));
        self.$set(self.states[stateKey], 'customers', response.data);
        self.$set(self.states[stateKey].meta, 'total', response.meta.total);
        callback(response.data)
      });
      return null
    },
    getCustomers (page, perPage, state) {
      let self = this;
      let params = '?page=' + page + '&per_page=' + perPage;
      if(state){
        params += '&state=' + state;
      }
      params += '&sort=' + self.sort.col + '&order=' + self.sort.order;

      for(let key in self.search) {
        if(key === 'klassen'){
          if(self.search[key].length > 0){
            for(let key2 in self.search[key]) {
              params += '&filter[klassenID][]=' + self.search[key][key2].id;
            }
          }
        }
        else if(key === 'last_activity' && self.search[key] != null){
          params += '&filter[' + key + ']=' + moment().subtract(self.search[key], "days").format('YYYY-MM-DD');
        }
        else if(self.search[key] != null){
          params += '&filter[' + key + ']=' + self.search[key];
        }
      }

      return Http.request('GET', '/customers/by_education' + params).then(function (response) {
        return response;
      });
    },
    moveCustomer(element, status_id) {
      if(element.added){
        this.$store.dispatch('customers/saveEducationState', {
          customer_id: element.added.element.customer.id,
          education_id: element.added.element.id,
          new_status_id: status_id,
        });
      }
    },
    setMultiselectCustomerStatus (old_status_id) {
      let self = this;
      let count = 0;
      self.selected_customers[old_status_id].forEach(function (education, key) {
        self.$store.dispatch('customers/saveEducationState', {
          customer_id: education.customer.id,
          education_id: education.id,
          new_status_id: self.selected_state,
        })
        .then(function(){
          count++
          if(count === self.selected_customers[old_status_id].length){
            self.$refs['customers_table_' + old_status_id][0].refresh();
            if(typeof(self.$refs['customers_table_' + self.selected_state]) !== "undefined"){
              self.$refs['customers_table_' + self.selected_state][0].refresh();
            }
            self.$bvModal.hide('multiselect_status-change_' + old_status_id);
            self.selected_customers =  {};
            self.selected_state = 0;
            self.select_all_customers = false;
          }
        });
      });
    },
    customerDeleted(table){
      this.$refs[table][0].refresh();
    },
    refreshStates(){
      this.states = {};
      this.getStates();
    },
    selectAllCustomers(value, status_id, customers){
      if(value){
        this.$set(this.selected_customers, status_id, customers);
      }
      else {
        this.$set(this.selected_customers, status_id, []);
      }
    },
    changeSort(sort, table){
      if(this.sort.col === sort && this.sort.order === 'asc'){
        this.sort.order = 'desc';
      }
      else if(this.sort.col === sort && this.sort.order === 'desc'){
        this.sort.col = 'activity';
      }
      else {
        this.sort.col = sort;
        this.sort.order = 'asc';
      }
      this.$refs[table][0].refresh();
    }
  }
}
</script>

<style scoped>
</style>
