<template>
  <b-form-group>
    <b-form-input type="text"
                  size="lg"
                  list="customer-search-list"
                  name="search_text"
                  v-model="search_text"
                  autocomplete="off"
                  debounce="500"
                  @change="selectCustomer"
                  @input="searchCustomers"
                  placeholder="Fahrschüler suchen">
    </b-form-input>
    <b-form-datalist id="customer-search-list" :options="search_result" value-field="name" text-field="name"></b-form-datalist>
  </b-form-group>
</template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "CustomerSearch",
  data(){
    return {
      search_text: '',
      search_cache: '',
      timeout: false,
      search_result: []
    }
  },
  methods: {
    searchCustomers (value) {
      let self = this;
      if(value.length > 2 && !self.timeout) {
        self.timeout = true;
        Http.request('GET', '/customers?page=1&per_page=10&filter[search]=' + value, null, false).then(function (response) {
          self.search_result = response.data;
          setTimeout(function (){
            self.timeout = false;
            if(self.search_cache !== value){
              self.searchCustomers(self.search_cache);
            }
          }, 500)
        });
      }
      else if (self.timeout){
        self.search_cache = value;
      }
    },
    selectCustomer (value) {
      let self = this;
      self.search_text = '';
      let customer = self.search_result.filter(item => item.name === value);
      if(customer.length > 0){
        Http.request('GET', '/customers/' + customer[0].id + '/educations').then((educations) => {
          self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + customer[0].id + '/' + educations.data[0].id + '/profile')
        });
      }
    },
  }
}
</script>

<style scoped>
</style>
